import dayjs from 'dayjs';
import Decimal from 'decimal.js';
import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { NumericFormat } from 'react-number-format';
import Select from 'react-select';
import { baseRequest } from '../../utils/requests';
import DeleteModal from '../DeleteModal';
import { PolicyContractProvider, usePolicyContracts } from './stateManagement';

// ******** selectors ********
// ===============================
// ===============================

const getProductsByContract = (state, contract_id) => {
  const products = [];
  state.products.forEach((c) => {
    if (c.contract_id === contract_id) {
      products.push({ ...c, label: c.product_class, value: c.id });
    }
  });
  return products;
};

const getContract = (state, id) => {
  return state.contracts.find((c) => c.id === id);
};

const getContractGroupTemplateContracts = ({ id }) => {
  return baseRequest('GET', `/contract_group_templates/${id}/contracts`).then((resp) => {
    return resp.data;
  });
};

const getFilteredContracts = ({ policy_contracts, contracts, contract_group_templates }) => {
  const filteredContracts = [];

  const filtered_contract_ids = policy_contracts.reduce((accu, currpc) => {
    if (!currpc.removed) {
      return [...accu, currpc.contract_id];
    }
    return accu;
  }, []);
  contracts.forEach((contract) => {
    if (filtered_contract_ids.includes(contract.id)) return;

    const today = dayjs();
    const startDate = dayjs(contract.effective_start_date);
    const endDate = dayjs(contract.end_date);

    // A contract is not selectable if it is inactive (hasn't completed the create flow),
    // if the start date is after today (contract has not started yet),
    // if the end date is before today (contract has already ended),
    const isDisabled = !contract.active || startDate.isAfter(today) || endDate.isBefore(today);

    if (!isDisabled) {
      filteredContracts.push({
        ...contract,
        label: contract.name,
        value: contract.id,
        isDisabled,
      });
    }
  });

  filteredContracts.sort((a, b) => {
    if (a.name < b.name) return -1;
    return 1;
  });

  filteredContracts.push({
    label: 'Contract Group Templates',
    options: contract_group_templates.map((cgt) => {
      return { ...cgt, label: cgt.name, value: cgt.id, isContractGroup: true };
    }),
  });

  return filteredContracts;
};

// TEST for 99.99999999 bug
// Policy Limit = 130,000,000
// 38.46153846153846;
// 34.61538461538461;
// 26.923076923076923;

const getTotalPercentage = (state) => {
  const { policy_contracts } = state;

  const totalPercentage = policy_contracts.reduce((acc, policy_contract) => {
    if (!policy_contract.removed) {
      const contract = getContract(state, policy_contract.contract_id);
      const value =
        policy_contract.default_percentage != null
          ? policy_contract.default_percentage
          : contract.default_allocation_percentage;
      acc += parseFloat(value) || 0;
    }
    return acc;
  }, 0);

  const formattedPercentage = new Decimal(totalPercentage).toFixed(8);
  return formattedPercentage == 100 ? 100 : formattedPercentage;
};

const monetaryValueFromPercentage = (percentage, valePolicyLimit) => {
  const percentageDecimal = new Decimal(percentage);
  if (percentageDecimal.isNaN() || percentageDecimal.isZero()) {
    return new Decimal(0);
  }
  return percentageDecimal.div(100).times(valePolicyLimit);
};

const getTotalMonetaryValue = (state, valePolicyLimit) => {
  const { policy_contracts } = state;

  const totalMonetaryValue = policy_contracts.reduce((acc, policy_contract) => {
    if (!policy_contract.removed) {
      const contract = getContract(state, policy_contract.contract_id);
      const value =
        policy_contract.default_monetary_value ||
        monetaryValueFromPercentage(
          new Decimal(
            policy_contract.default_percentage || contract.default_allocation_percentage || 0
          ),
          valePolicyLimit
        );
      acc = acc.plus(value);
    }
    return acc;
  }, new Decimal(0.0));

  return totalMonetaryValue.toNumber().toFixed(2);
};

const getTotalDisplay = (state, displayPercentage) => {
  if (displayPercentage) {
    return `${getTotalPercentage(state)}%`;
  }

  const monetaryValue = getTotalMonetaryValue(state, state.policy.vale_policy_limit);

  const formattedUsd = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 20,
  }).format(monetaryValue);

  return `$${formattedUsd}`;
};

// rendered

const PolicyContracts = () => {
  const { state, removedPolicyContracts, policyContracts } = usePolicyContracts();
  const [copiedContractId, setCopiedContractId] = useState(false);
  const [displayPercentage, setDisplayPercentage] = useState(true);

  const handleCopy = (contractId) => {
    setCopiedContractId(contractId);
  };

  const toggleDisplay = () => {
    setDisplayPercentage(!displayPercentage);
  };

  const contains_locked_contracts = policyContracts.some((pc) => pc.locked || pc.frozen);

  const formDisabled = (state, displayPercentage) => {
    if (contains_locked_contracts) {
      return true;
    }
    if (displayPercentage) {
      // Soft equals 100.00 or 100.0000 or 100.00000 == 100
      return getTotalPercentage(state) != 100;
    }

    const valePolicyLimit = parseFloat(state.policy.vale_policy_limit) || 0;

    if (valePolicyLimit === 0) {
      return true;
    }

    const totalMonetaryValue = getTotalMonetaryValue(state, valePolicyLimit);

    return totalMonetaryValue != valePolicyLimit;
  };

  return (
    <>
      <div className="segment">
        <div className="segment-label">VALE Policy Limit Allocation</div>
        <div className="segment-controls">
          <div className={`segment-control ${!displayPercentage ? '' : 'active'}`}>
            <span onClick={!displayPercentage ? toggleDisplay : undefined}>Percentage</span>
          </div>
          <div className={`segment-control ${displayPercentage ? '' : 'active'}`}>
            <span onClick={displayPercentage ? toggleDisplay : undefined}>Amount</span>
          </div>
        </div>
      </div>

      {policyContracts.map((pc, i) => {
        return (
          <PolicyContract
            key={pc.contract_id}
            index={i}
            policy_contract={pc}
            isCopied={copiedContractId === pc.contract_id}
            onCopy={handleCopy}
            displayPercentage={displayPercentage}
            disabled={formDisabled(state, displayPercentage) ? 'disabled' : ''}
          />
        );
      })}

      {removedPolicyContracts.map((pc, i) => {
        return <DeletedPolicyContract key={pc.contract_id} policy_contract={pc} />;
      })}
      <p>Total: {getTotalDisplay(state, displayPercentage)}</p>
      <div className="flex justify-end">
        <input
          type="submit"
          value="Save"
          disabled={formDisabled(state, displayPercentage) ? 'disabled' : ''}
          data-action="freeze-period#submit"
          // data-turbo={}
        />
      </div>
    </>
  );
};

const Header = () => {
  const { state, addPolicyContract, deletePolicyContract, policyContracts } = usePolicyContracts();
  const [key, setKey] = useState(0);
  let existingContract = false;
  const savedPolicyContractIds = state?.policy_contracts?.map((contract) => contract);
  const contains_locked_contracts = policyContracts.some((pc) => pc.locked || pc.frozen);

  const onChange = (e) => {
    const { isContractGroup } = e;
    if (isContractGroup) {
      const contractsResp = getContractGroupTemplateContracts(e);
      contractsResp.then((resp) => {
        const newContractsIds = resp.contracts.map((contract) => contract.id);
        let commonContracts = savedPolicyContractIds.filter((contract) =>
          newContractsIds.includes(contract.contract_id)
        );
        if (
          savedPolicyContractIds &&
          savedPolicyContractIds.length > 0 &&
          commonContracts.length > 0
        ) {
          existingContract = true;
        }
        if (existingContract) {
          const answer = confirm(
            'This contract already exists on the policy. If you continue the exisiting contract will be removed.'
          );
          if (answer) {
            console.log(commonContracts);
            commonContracts.forEach((contract) => {
              console.log(contract);
              deletePolicyContract(contract);
            });
          } else {
            return;
          }
        }

        resp.contracts.forEach((contract) => {
          setKey(contract.id);
          addPolicyContract(contract);
        });
      });
    } else {
      setKey(e.id);
      addPolicyContract(e);
    }
  };

  return (
    <>
      <div className="add-carrier-header flex space-between">
        <p>Carriers</p>
        <div style={{ width: '50%' }}>
          <Select
            key={key}
            className="react-selector-container"
            classNamePrefix="react-selector"
            isSearchable={true}
            onChange={onChange}
            placeholder="Add Contract"
            options={getFilteredContracts(state)}
            noOptionsMessage={() => 'There are no valid contracts to add.'}
            isDisabled={contains_locked_contracts}
          />
        </div>
      </div>
      <div className="baseline"></div>
    </>
  );
};

const PolicyContract = ({ policy_contract, isCopied, onCopy, disabled, displayPercentage }) => {
  const { state, removePolicyContract } = usePolicyContracts();
  const contract = getContract(state, policy_contract.contract_id);
  const [copyIsPressed, setCopyIsPressed] = useState(false);
  policy_contract.default_allocation_percentage = contract.default_allocation_percentage;
  useEffect(() => {
    if (copyIsPressed) {
      setTimeout(() => {
        setCopyIsPressed(false);
      }, 3000);
    }
  }, [copyIsPressed]);

  const handleCopy = () => {
    setCopyIsPressed(true);
  };

  // const redirectToContract = id => {
  //   window.location = `/edit_contract/${contract.id}/build/details/edit`;
  // }
  return (
    <div className="policy-contract-card" data-cy={`policy-contract-card-${contract.id}`}>
      <div className="flex space-between items-start">
        <div
          // onClick={() => redirectToContract(contract.id) }
          style={{ color: 'darkslateblue' }}
          // className="hover: cursor-pointer"
        >
          <p> {contract.name} </p>
          {policy_contract.policy_number && (
            <div className="clipboard-container">
              <em>{policy_contract.policy_number}</em>
              {!copyIsPressed && (
                <div>
                  <CopyToClipboard
                    text={policy_contract.policy_number}
                    onCopy={() => {
                      onCopy(policy_contract.contract_id);
                      handleCopy();
                    }}>
                    <span className="copy-button">Copy</span>
                  </CopyToClipboard>
                  <i className="fa fa-clipboard fa-lg"></i>
                </div>
              )}
              {isCopied && copyIsPressed && (
                <div>
                  <span className="copied">Copied</span>
                  <i className="fa fa-check fa-lg"></i>
                </div>
              )}
            </div>
          )}
          {!policy_contract.policy_number && 'no policy number'}
        </div>

        <DeleteModal
          resourceType={`deals/${state.policy.deal_id}/policies/${state.policy.id}/contracts`}
          resourceId={policy_contract.id}
          code="ValeDeleteContract"
          resourceName="Policy Contract"
          removeFromListFunction={removePolicyContract}
          itemToRemove={policy_contract}
          disabled={policy_contract.locked || policy_contract.frozen}
          isAdmin={state.role}
        />
      </div>

      <div className="contract-fields flex my-4">
        <PercentInput
          policy={state.policy}
          policy_contract={policy_contract}
          disabled={policy_contract.locked || policy_contract.frozen}
          displayPercentage={displayPercentage}
        />
      </div>

      <div className="flex justify-center">
        <PolicyNumberButtons policy_contract={policy_contract} role={state.role} />
      </div>

      {/*
       * This needs to be rendered regardless of the policy_contract.id presence.
       * If it isn't rendered, Rails will merge the params for the new relation
       * with the _destroy param. Essentially creating the new policy_contract and
       * immediately destroying it.
       */}
      <input
        type="hidden"
        name="policy[policy_contracts_attributes][][id]"
        value={policy_contract.id}
      />

      <input
        type="hidden"
        name="policy[policy_contracts_attributes][][policy_id]"
        value={policy_contract.policy_id}
      />

      <input
        type="hidden"
        name="policy[policy_contracts_attributes][][contract_id]"
        value={policy_contract.contract_id}
      />
      {state.role === 'admin' && policy_contract.locked && (
        <input type="hidden" name="policy[policy_contracts_attributes][][locked]" value={false} />
      )}
    </div>
  );
};

const PolicyNumberButtons = ({ policy_contract, role }) => {
  const { togglePolicyNumber, manuallyAssignPolicyNumber } = usePolicyContracts();
  const text = policy_contract.policy_number ? 'Remove' : 'Assign';
  const disabled =
    policy_contract.id && !policy_contract.locked && !policy_contract.frozen ? '' : 'disabled';
  const [showManualAssign, setShowManualAssign] = useState(false);
  const handleClick = (e) => {
    e.preventDefault();
    togglePolicyNumber(policy_contract);
  };

  const handleManualAssign = (e) => {
    e.preventDefault();
    setShowManualAssign(true);
  };
  return (
    <div>
      {(role === 'underwriter' || role === 'admin') && (
        <button
          className="btn btn-alt float-left manual-policy-number-button"
          onClick={handleManualAssign}
          disabled={disabled}>
          {`Manually Assign`}
        </button>
      )}
      <button className="btn btn-alt float-right" onClick={handleClick} disabled={disabled}>
        {text}
      </button>
      <br />
      {!policy_contract.id ? (
        <p className="float-right">You must save the contract to assign a policy number.</p>
      ) : (
        ''
      )}
      {showManualAssign && (
        <ManualAssign
          policy_contract={policy_contract}
          manuallyAssignPolicyNumber={manuallyAssignPolicyNumber}
        />
      )}
    </div>
  );
};

const ManualAssign = ({ policy_contract, manuallyAssignPolicyNumber }) => {
  const [policyNumber, setPolicyNumber] = useState('');
  const savePolicyNumber = (e) => {
    manuallyAssignPolicyNumber({ ...policy_contract, policyNumber });
  };

  return (
    <div className="field">
      <label htmlFor="policy_number">Enter Policy Number:</label>
      <input
        type="text"
        id="policy_number"
        value={policyNumber}
        onChange={(e) => setPolicyNumber(e.target.value)}
      />
      <button className="btn btn-alt float-right" onClick={savePolicyNumber}>
        Save
      </button>
    </div>
  );
};

const PercentInput = ({ policy, policy_contract, disabled, displayPercentage }) => {
  const { dispatch } = usePolicyContracts();
  const valePolicyLimit = policy.vale_policy_limit || 0;
  const valePolicyLimitSet = !(
    parseFloat(valePolicyLimit) === 0 ||
    valePolicyLimit === undefined ||
    valePolicyLimit === null
  );
  const percentageValue =
    policy_contract.default_percentage !== null
      ? policy_contract.default_percentage
      : policy_contract.default_allocation_percentage;

  const monetaryValue =
    policy_contract.default_monetary_value !== undefined
      ? policy_contract.default_monetary_value
      : new Decimal(percentageValue || 0).div(100).times(valePolicyLimit).toNumber().toFixed(2);

  const onPercentChange = (e) => {
    const newPercentage = new Decimal(e || 0);

    const default_monetary_value = () => {
      if (!valePolicyLimitSet) {
        return null;
      }
      return newPercentage.div(100).times(valePolicyLimit).toNumber().toFixed(2);
    };

    const defaultMonetaryValue = default_monetary_value();

    dispatch({
      type: 'change_percentage',
      id: policy_contract.contract_id,
      default_percentage: newPercentage.toNumber(),
      default_monetary_value: defaultMonetaryValue !== null ? defaultMonetaryValue : monetaryValue,
    });
  };

  const onMonetaryValueChange = (e) => {
    const newMonetaryValue = new Decimal(e || 0);

    const default_percentage = () => {
      if (!valePolicyLimitSet) {
        return null;
      }
      return newMonetaryValue.times(100).div(valePolicyLimit).toNumber();
    };
    const defaultPercentage = default_percentage();

    dispatch({
      type: 'change_percentage',
      id: policy_contract.contract_id,
      default_percentage: defaultPercentage !== null ? defaultPercentage : percentageValue,
      default_monetary_value: newMonetaryValue.toNumber().toFixed(2),
    });
  };

  return (
    <>
      <div className={displayPercentage ? 'flex-1 mr-3' : 'hidden'}>
        <label htmlFor="">Percentage</label>
        <input
          placeholder="percentage"
          type="number"
          name="policy[policy_contracts_attributes][][default_percentage]"
          min="0"
          max="100"
          step="any"
          value={percentageValue}
          onChange={(e) => {
            if (displayPercentage) {
              onPercentChange(e.target.value);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === '-') {
              e.preventDefault();
            }
          }}
          disabled={disabled}
        />
      </div>
      <div className={!displayPercentage ? 'flex-1 mr-3' : 'hidden'}>
        <label htmlFor="">Amount</label>
        <NumericFormat
          placeholder="money value"
          type="text"
          name="default_monetary_value"
          min="0"
          step="any"
          value={monetaryValue}
          allowedDecimalSeparators={['.']}
          thousandsGroupStyle="thousand"
          thousandSeparator=","
          onValueChange={(values) => {
            if (!displayPercentage) {
              const { floatValue } = values;
              onMonetaryValueChange(floatValue);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === '-') {
              e.preventDefault();
            }
          }}
          disabled={disabled}
        />
      </div>
    </>
  );
};

const CarrierPrefixInput = ({ policy_contract: { contract_id, carrier_prefix } }) => {
  const { state } = usePolicyContracts();
  const contract = getContract(state, contract_id);
  const prefixes = Array.isArray(contract.carrier_prefix)
    ? contract.carrier_prefix.map((e) => ({ label: e, value: e }))
    : [];
  const initialPrefix = carrier_prefix ? { label: carrier_prefix, value: carrier_prefix } : null;
  const [prefix, setPrefix] = useState(initialPrefix);

  return (
    <div className="flex-1 mr-3">
      <label htmlFor="">Carrier Prefix</label>
      <Select
        className="react-selector-container"
        classNamePrefix="react-selector"
        isSearchable={false}
        onChange={(e) => {
          setPrefix(e);
        }}
        value={prefix}
        options={prefixes}
      />

      <input
        type="hidden"
        name="policy[policy_contracts_attributes][][carrier_prefix]"
        defaultValue={prefix ? prefix.value : null}
      />
    </div>
  );
};

const ProductInput = ({ policy_contract }) => {
  const { state } = usePolicyContracts();
  const products = getProductsByContract(state, policy_contract.contract_id);
  const initialProduct = products.find((e) => e.id === policy_contract.product_id);
  const [product, setProduct] = useState(initialProduct);

  return (
    <div className="flex-1 mr-3">
      <label htmlFor="">Product Class</label>
      <Select
        className="react-selector-container"
        classNamePrefix="react-selector"
        isSearchable={false}
        onChange={(e) => {
          setProduct(e);
        }}
        value={product}
        options={products}
        autoFocus={true}
      />
      <input
        type="hidden"
        name="policy[policy_contracts_attributes][][product_id]"
        value={product && product.id ? product.id : ''}
      />
    </div>
  );
};

const ReferralInput = ({ policy_contract }) => {
  const { state } = usePolicyContracts();
  const statuses = state.referral_statuses.map((e) => {
    return { value: e, label: e };
  });
  const [referralStatus, setReferralStatus] = useState(
    policy_contract.referral_status
      ? {
          label: policy_contract.referral_status,
          value: policy_contract.referral_status,
        }
      : null
  );

  return (
    <div className="flex-1">
      <label htmlFor="">Referral Status</label>
      <Select
        value={referralStatus}
        className="react-selector-container"
        classNamePrefix="react-selector"
        isSearchable={false}
        onChange={(e) => setReferralStatus(e)}
        options={statuses}
      />

      <input
        type="hidden"
        name="policy[policy_contracts_attributes][][referral_status]"
        value={referralStatus ? referralStatus.value : ''}
      />
    </div>
  );
};

const DeletedPolicyContract = ({ policy_contract }) => {
  return (
    <>
      <input
        type="hidden"
        name="policy[policy_contracts_attributes][][id]"
        value={policy_contract.id}
      />
      <input type="hidden" name="policy[policy_contracts_attributes][][_destroy]" value="1" />
    </>
  );
};

const PolicyContractsContainer = (props) => {
  return (
    <div className="mt-5">
      <PolicyContractProvider {...props}>
        <Header />
        <PolicyContracts />
      </PolicyContractProvider>
    </div>
  );
};

export default PolicyContractsContainer;

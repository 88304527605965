import axios from 'axios';

export let baseRequest = (method, url, params) => {
  return axios({
    method,
    url,
    params,
    headers: {
      'X-CSRF-Token':
        process.env.RAILS_ENV !== 'test' && document.querySelector('meta[name=csrf-token]').content,
    },
  });
};

export let post = (url, params) => {
  return axios({
    method: 'post',
    url,
    data: params,
    headers: {
      'X-CSRF-Token':
        process.env.RAILS_ENV !== 'test' && document.querySelector('meta[name=csrf-token]').content,
    },
  });
};

export let getResource = (resource, params, includeEmpty) => {
  return baseRequest('GET', `/${resource}s.json`, params)
    .then(resp => {
      let data = resp.data[`${resource}s`].map((item, index) => {
        return { type: resource, value: item.id, label: item.name };
      });
      let emptyValue = { type: resource, value: null, label: 'NA' };
      return includeEmpty ? [... data, emptyValue] : data;
    })
    .catch(err => {
      console.log('err:', err);
      throw Error(err);
    });
};

export let getClients = (resource, params) => {};

import React, { useEffect, useState } from 'react';
import { baseRequest } from '../utils/requests';

export default function UpdatePageWatcher({ deal_id, deal }) {
  const initialStatus = deal.sharepoint_deal_directory !== null && deal.sharepoint_deal_directory_id !== null;
  const [containsSharePointData, setContainsSharePointData] = useState(initialStatus);

  useEffect(() => {
    if (!containsSharePointData) {
      const intervalId = setInterval(function () {
        baseRequest('GET', `/deals/${deal_id}/watcher`)
          .then(resp => {
            console.log(resp.data.contains_sharepoint_data);
            if (resp.data.contains_sharepoint_data) {
              setContainsSharePointData(true);
              window.location.reload();
            }
          });
      }, 5000);
      if (containsSharePointData) {
        console.log("clearing interval");
        return () => {
          clearInterval(intervalId);
        };
      }
    }
  }, []);

  return (
    <>
    </>
  );
}
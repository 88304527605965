// actions
import { post, baseRequest } from '../utils/requests';

export const TOGGLE_MODAL = 'toggle_modal';
export const ADD_DEAL_UNDERWRITER = 'add_deal_underwriter';
export const REMOVE_DEAL_UNDERWRITER = 'remove_deal_underwriter';
export const ADD_UNDERWRITER = 'add_underwriter';
export const REMOVE_UNDERWRITER = 'remove_underwriter';

export const initialState = {
  currentUser: {},
  dealUnderwriters: [],
  currentUnderwriterState: [],
  deal: {},
  users: {},
  modalOpen: false,
};

export const add = (state, dispatch, underwriter) => {
  let request = post('/deal_underwriters', {
    deal_underwriter: {
      deal_id: state.deal.id,
      underwriter_id: underwriter.id,
    },
  });

  return request.then(resp => {
    if (resp.status == 200) {
      let {
        data: { deal_underwriter },
      } = resp;
      let added_underwriter = state.users.find(u => u.id === underwriter.id);
      dispatch({
        type: ADD_DEAL_UNDERWRITER,
        dealUnderwriter: deal_underwriter,
      });
      dispatch({ type: ADD_UNDERWRITER, underwriter: added_underwriter });
      dispatch({ type: TOGGLE_MODAL });
    }
  });
};

export const remove = (state, dispatch, underwriter) => {
  let dealUnderwriter = state.dealUnderwriters.find(i => i.underwriter_id === underwriter.id);

  return baseRequest('DELETE', `/deal_underwriters/${dealUnderwriter.id}`).then(resp => {
    if (resp.status == 200) {
      dispatch({ type: REMOVE_DEAL_UNDERWRITER, dealUnderwriter });
      dispatch({ type: REMOVE_UNDERWRITER, underwriter });
    }
  });
};

export function pickerReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MODAL:
      return { ...state, modalOpen: !state.modalOpen };

    case ADD_DEAL_UNDERWRITER:
      return {
        ...state,
        dealUnderwriters: [...state.dealUnderwriters, action.dealUnderwriter],
      };

    case REMOVE_DEAL_UNDERWRITER:
      let dealUnderwriters = state.dealUnderwriters.filter(i => i.id != action.dealUnderwriter.id);
      return { ...state, dealUnderwriters };

    case ADD_UNDERWRITER:
      return {
        ...state,
        currentUnderwriters: [...state.currentUnderwriters, action.underwriter],
      };

    case REMOVE_UNDERWRITER:
      let currentUnderwriters = state.currentUnderwriters.filter(
        i => i.id != action.underwriter.id
      );
      return { ...state, currentUnderwriters };

    default:
      return state;
  }
}

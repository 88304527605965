import React, { useMemo, useContext, useReducer, createContext } from 'react';
import * as CurForm from 'currency-formatter';
import Big from 'big.js';

// inital state/props
// {pc_index, policy_contract, carrier_contracts, contract_actuals }
// const initiatState = { pc_index: 0, policy_contract: {}, carrier_contracts: [], contract_actuals: [] }

const ContractActualContext = createContext();

export const reducer = (state, action) => {
  switch (action.type) {
    case 'update_actual_percentage':
      return {
        ...state,
        contractActuals: state?.contractActuals.map((ca, i) => {
          if (action.payload.actualIndex === i) {
            return {
              ...state.contractActuals[action.payload.actualIndex],
              actual: action.payload.actual,
            };
          }
          return ca;
        }),
      };
    default:
      throw new Error('we dont support no state!');
  }
};

export const useContractActuals = () => {
  const context = useContext(ContractActualContext);
  if (!context) {
    throw new Error('useContractActuals requires a context');
  }
  return context;
};

export const ContractActualsProvider = props => {
  const contractActuals = props?.carrier_contracts?.map(carrierContract => {
    const { carrier_name, id, default_percentage } = carrierContract;
    let contractActual = props.contract_actuals.find(
      ca => ca.carrier_contract_id === carrierContract.id
    );
    return {
      id: contractActual ? contractActual.id : null,
      actual: contractActual
        ? contractActual.actual_percentage
        : generateActualPercentagePlaceholder(carrierContract, props.policy_contract),
      carrier_name: carrier_name,
      carrier_contract_id: id,
      default_percentage: default_percentage,
    };
  });

  const [state, dispatch] = useReducer(reducer, { ...props, contractActuals });
  const value = useMemo(() => [state, dispatch], [state]);
  return <ContractActualContext.Provider value={value} {...props} />;
};

// START STATE SELECTORS
const format = (num, code) => CurForm.format(num.toFixed(2), { code, locale: 'en-US' });

// bye
export const getCaStats = contractActual => {
  let [state] = useContractActuals();
  const { policy_contract_limit, policy_contract_premium, policy_currency } = state;
  const policyContractLimit = Big(policy_contract_limit);
  const policyContractPremium = Big(policy_contract_premium);
  const percent = contractActual.actual
    ? Big(contractActual.actual).div(100.0)
    : Big(contractActual.default_percentage).div(100.0);

  return [
    format(policyContractLimit.times(percent), policy_currency),
    format(policyContractPremium.times(percent), policy_currency),
  ];
};

export const percentageLeft = state => {
  const total = Big(state?.policy_contract?.default_percentage);
  let left = Big(0.0);
  state?.contractActuals?.forEach(ca => {
    if (ca.actual) {
      left = left.add(parseFloat(ca.actual));
    }
  });

  if (total && total.minus) {
    return total.minus(left).toString();
  }
};

// CONTRACT ACTUAL DEFAULT VALUE
// USED TO PREFILL THE ACTUAL PERCENT INPUTE IF THE contract_actual DOESN'T EXIST
const generateActualPercentagePlaceholder = (carrierContract, policyContract) => {
  const carrierDefaultPercentage = carrierContract?.default_percentage ?? 0; // Provide a default value if null
  const policyDefaultPercentage = policyContract?.default_percentage ?? 0; // Provide a default value if null

  return Big(carrierDefaultPercentage)
    .times(Big(policyDefaultPercentage))
    .div(100.0)
    .toFixed(2);
};

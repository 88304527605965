import React, { useState } from 'react';
import Modal from './Modal';
import { baseRequest } from '../utils/requests';

export default function DeleteModal({ resourceType, resourceId, code, resourceName, removeFromListFunction, itemToRemove, disabled, isAdmin }) {
  const [isOpen, setIsOpen] = useState(false);
  const [deleteCode, setDeleteCode] = useState(null);

  const handleDelete = () => {
    if (deleteCode !== code && resourceName !== "Policy Contract") return;

    return baseRequest('DELETE', `/${resourceType}/${resourceId}`)
      .then(resp => {
        if (resp.status === 204) {
          setIsOpen(false);
          window.location.reload();
        }
      })
  }

  const removePolicyContract = () => {
    setIsOpen(false);
    removeFromListFunction(itemToRemove);
  }

  return (
    <div>
      <button disabled={disabled} onClick={(event) => { event.preventDefault(); setIsOpen(true); }}>
        <i className="fas fa-trash"></i>
      </button>

      <Modal open={isOpen}>
        <div className='field w-full'>
          <h1 className="text-3xl text-center mb-3">Delete {resourceName}</h1>
          <p className='mb-3 text-center'>
            Are you sure? This action cannot be reversed.
            <br/>
          </p>
          {/* Display the input field only if it is not the policy contract */}
          {resourceName !== "Policy Contract" && (
            <p>
              Type the {resourceName.toLowerCase()} delete code below to confirm this action.
          <div className="field w-full">
            <input
              id="delete_code"
              type="text"
              defaultValue={deleteCode}
              onChange={(e) => setDeleteCode(e.target.value)}
                  disabled={disabled}
            />
          </div>
            </p>
          )}
          <button className='btn mr-5' onClick={() => setIsOpen(false)}>Cancel</button>
          <button className="btn btn-warning" id='test' value="confirm" onClick={(resourceName === "Policy Contract" && resourceId == undefined) ? removePolicyContract : handleDelete}>Confirm Delete</button>
        </div>
      </Modal>
    </div>
  )
}

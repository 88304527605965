import React, { useContext, useReducer, createContext } from 'react';
import { post } from '../../utils/requests';

// initial state we might
const initialState = props => {
  return { ...props, children: null };
};

// **** context & provider *******
// ===============================
// ===============================

const PolicyContractContext = createContext({});

export const PolicyContractProvider = props => {
  const initialStore = initialState(props);
  const [state, dispatch] = useReducer(policyContractReducer, initialStore);
  return <PolicyContractContext.Provider value={[state, dispatch]} {...props} />;
};

// ******** reducer **************
// ===============================
// ===============================
const policyContractReducer = (state, action) => {
  switch (action.type) {
    case 'add_policy_contract':
      const current_policy_contract = state.policy_contracts.find(
        pc => pc.contract_id == action.policy_contract.id
      );
      if (!current_policy_contract) {
      return {
        ...state,
        policy_contracts: [...state.policy_contracts, action.policy_contract],
      };
      }
    case 'toggle_policy_contract':
      if (action.policy_contract.id) {
        return {
          ...state,
          policy_contracts: state.policy_contracts.map(pc => {
            if (pc.id === action.policy_contract.id) {
              return { ...pc, removed: !pc.removed };
            }
            return pc;
          }),
        };
      }
      return {
        ...state,
        policy_contracts: state.policy_contracts.filter(
          pc => pc.contract_id !== action.policy_contract.contract_id
        ),
      };

    case 'remove_policy_contract':
      return {
        ...state,
        policy_contracts: state.policy_contracts.filter(
          pc => pc.id !== action.policy_contract.id
        ),
      };

    case 'change_percentage':
      return {
        ...state,
        policy_contracts: state.policy_contracts.map(pc => {
          if (pc.contract_id === action.id) {
            return { ...pc, default_percentage: action.default_percentage, default_monetary_value: action.default_monetary_value };
          } else {
            return pc;
          }
        }),
      };
    case 'assign_policy_number':
      return {
        ...state,
        policy_contracts: state.policy_contracts.map(pc => {
          if (pc.id === action.policy_contract_id) {
            return { ...pc, policy_number: action.policy_number };
          } else {
            return pc;
          }
        }),
      };
    default:
      return state;
  }
};

// ******* StateWrapper **********
// ===============================
// ===============================
export const usePolicyContracts = () => {
  const [state, dispatch] = useContext(PolicyContractContext);
  const removedPolicyContracts = state.policy_contracts.filter(pc => pc.removed);
  const policyContracts = state.policy_contracts.filter(pc => !pc.removed);
  const addPolicyContract = contract => {

    const current_policy_contract = state.policy_contracts.find(
      pc => pc.contract_id == contract.id
    );
    // if (!current_policy_contract) {
      dispatch({
        type: 'add_policy_contract',
        policy_contract: {
          new: true,
          policy_id: state.policy.id,
          contract_id: contract.id,
          policy_number: null,
          default_percentage: null,
        },
      });
    // }
  };

  const deletePolicyContract = async policy_contract => {
    dispatch({ type: 'remove_policy_contract', policy_contract });
  }

  const removePolicyContract = policy_contract => {
    dispatch({ type: 'toggle_policy_contract', policy_contract });
  };

  const togglePolicyNumber = async policy_contract => {
    const url = `/deals/${state.policy.deal_id}/policies/${state.policy.id}/toggle_policy_number`;
    const resp = await post(url, {
      policy_contract_id: policy_contract.id,
    });

    dispatch({
      type: 'assign_policy_number',
      policy_contract_id: policy_contract.id,
      policy_number: resp.data.policy_number,
    });
  };

  const manuallyAssignPolicyNumber = async policy_contract => {
    const url = `/deals/${state.policy.deal_id}/policies/${state.policy_id}/manually_assign_policy_number`;
    const resp = await post(url, {
      policy_contract_id: policy_contract.id,
      policy_number: policy_contract.policyNumber
    });

    dispatch({
      type: 'manually_assign_policy_number',
      policy_contract_id: policy_contract.id,
      policy_number: policy_contract.policyNumber
    });
  }

  return {
    state,
    dispatch,
    removedPolicyContracts,
    policyContracts,
    addPolicyContract,
    removePolicyContract,
    togglePolicyNumber,
    manuallyAssignPolicyNumber,
    deletePolicyContract
  };
};
